import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    name: 'Home',
    path: '/',
    component: () => import('../views/Home.vue')
  },
  {
    name: 'NotFound',
    path: '*',
    component: () => import('../views/NotFound.vue')
  },
  {
    name: 'AkiMain',
    path: '/aki',
    component: () => import('../views/aki/Main.vue'),
    children: [
      {
        name: 'AkiHome',
        path: '/',
        component: () => import('../views/aki/Home.vue')
      },
      {
        name: 'AkiTicket',
        path: 'ticket/:id',
        component: () => import('../views/aki/Ticket.vue'),
        props: true
      },
      {
        name: 'AkiPaymentSuccess',
        path: 'payment-success/:external_id',
        component: () => import('../views/aki/PaymentSuccess.vue'),
        props: true
      },
      {
        name: 'AkiPaymentFailed',
        path: 'payment-failed/:external_id',
        component: () => import('../views/aki/PaymentFailed.vue'),
        props: true
      },
      {
        name: 'AkiMediaPartnerContract',
        path: 'media-partner-contract',
        component: () => import('../views/aki/MediaPartnerContract.vue')
      },
      {
        name: 'AkiPrivacyPolicy',
        path: 'privacy-policy',
        component: () => import('../views/aki/PrivacyPolicy.vue')
      },
      {
        name: 'AkiSafetySecurityPolicy',
        path: 'safety-security-policy',
        component: () => import('../views/aki/SafetySecurityPolicy.vue')
      },
      {
        name: 'AkiAntiHarassmentPolicy',
        path: 'anti-harassment-policy',
        component: () => import('../views/aki/AntiHarassmentPolicy.vue')
      },
      {
        name: 'AkiTermsCondition',
        path: 'terms-and-conditions',
        component: () => import('../views/aki/TermsCondition.vue')
      },
      {
        name: 'AkiExamplePost',
        path: 'example-post',
        component: () => import('../views/aki/ExamplePost.vue')
      },
      {
        name: 'AkiContest',
        path: 'contest/:id',
        component: () => import('../views/aki/Contest.vue'),
        props: true
      },
      {
        name: 'AkiReader',
        path: 'reader',
        component: () => import('../views/aki/Reader.vue')
      },
      {
        name: 'AkiTenant',
        path: 'tenant',
        component: () => import('../views/aki/Tenant.vue')
      },
      {
        name: 'AkiFanartVoting',
        path: 'fanart-voting',
        component: () => import('../views/aki/FanartVoting.vue')
      },
      {
        name: 'AkiNotFound',
        path: '*',
        component: () => import('../views/aki/NotFound.vue')
      }
    ]
  },
  {
    name: 'FuyuMain',
    path: '/fuyu',
    component: () => import('../views/fuyu/Main.vue'),
    children: [
      {
        name: 'FuyuHome',
        path: '/',
        component: () => import('../views/fuyu/Home.vue')
      },
      {
        name: 'FuyuTicket',
        path: 'ticket/:id',
        component: () => import('../views/fuyu/Ticket.vue'),
        props: true
      },
      {
        name: 'FuyuPaymentSuccess',
        path: 'payment-success/:external_id',
        component: () => import('../views/fuyu/PaymentSuccess.vue'),
        props: true
      },
      {
        name: 'FuyuPaymentFailed',
        path: 'payment-failed/:external_id',
        component: () => import('../views/fuyu/PaymentFailed.vue'),
        props: true
      },
      {
        name: 'FuyuPrivacyPolicy',
        path: 'privacy-policy',
        component: () => import('../views/fuyu/PrivacyPolicy.vue')
      },
      {
        name: 'FuyuSafetySecurityPolicy',
        path: 'safety-security-policy',
        component: () => import('../views/fuyu/SafetySecurityPolicy.vue')
      },
      {
        name: 'FuyuAntiHarassmentPolicy',
        path: 'anti-harassment-policy',
        component: () => import('../views/fuyu/AntiHarassmentPolicy.vue')
      },
      {
        name: 'FuyuTermsCondition',
        path: 'terms-and-conditions',
        component: () => import('../views/fuyu/TermsCondition.vue')
      },
      // {
      //   name: 'FuyuTenant',
      //   path: 'tenant',
      //   component: () => import('../views/fuyu/Tenant.vue')
      // },
      {
        name: 'FuyuReader',
        path: 'reader',
        component: () => import('../views/fuyu/Reader.vue')
      },
      {
        name: 'FuyuNotFound',
        path: '*',
        component: () => import('../views/fuyu/NotFound.vue')
      }
    ]
  },
  {
    name: 'HaruMain',
    path: '/haru',
    component: () => import('../views/haru/Main.vue'),
    children: [
      {
        name: 'HaruHome',
        path: '/',
        component: () => import('../views/haru/Home.vue')
      },
      {
        name: 'HaruTicket',
        path: 'ticket/:id',
        component: () => import('../views/haru/Ticket.vue'),
        props: true
      },
      {
        name: 'HaruPaymentSuccess',
        path: 'payment-success/:external_id',
        component: () => import('../views/haru/PaymentSuccess.vue'),
        props: true
      },
      {
        name: 'HaruPaymentFailed',
        path: 'payment-failed/:external_id',
        component: () => import('../views/haru/PaymentFailed.vue'),
        props: true
      },
      {
        name: 'HaruPrivacyPolicy',
        path: 'privacy-policy',
        component: () => import('../views/haru/PrivacyPolicy.vue')
      },
      {
        name: 'HaruSafetySecurityPolicy',
        path: 'safety-security-policy',
        component: () => import('../views/haru/SafetySecurityPolicy.vue')
      },
      {
        name: 'HaruAntiHarassmentPolicy',
        path: 'anti-harassment-policy',
        component: () => import('../views/haru/AntiHarassmentPolicy.vue')
      },
      {
        name: 'HaruTermsCondition',
        path: 'terms-and-conditions',
        component: () => import('../views/haru/TermsCondition.vue')
      },
      // {
      //   name: 'HaruTenant',
      //   path: 'tenant',
      //   component: () => import('../views/haru/Tenant.vue')
      // },
      {
        name: 'HaruReader',
        path: 'reader',
        component: () => import('../views/haru/Reader.vue')
      },
      {
        name: 'HaruNotFound',
        path: '*',
        component: () => import('../views/haru/NotFound.vue')
      }
    ]
  },
]

const router = new VueRouter({
  hash: false,
  mode: 'history',
  linkExactActiveClass: 'active',
  base: process.env.BASE_URL,
  routes
})

router.afterEach(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})

export default router
